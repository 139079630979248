import React from "react"
import { graphql, useStaticQuery } from "gatsby"

import LayoutGalerieImageInteractive from "../../../composants/layout/LayoutGalerieImageInteractive"
import Pannellum from "../../../composants/image/Panellum"

import { obtenirPage } from "../../../js/client-es/utils"
import fragmentsPages from "../../../js/client-es/fragments/fragments-pages"

export const requete = graphql`
  {
    pageGodafoss360: allPagesCompilationYaml(
      filter: { code: { eq: "godafoss360" } }
    ) {
      ...FragmentPageYaml
    }
  }
`

export default function Godafoss360(props) {
  const resultatsRequete = useStaticQuery(requete)
  const page = obtenirPage(resultatsRequete, "pageGodafoss360")

  const composantImageInteractive = (
    <Pannellum
      urlImageSource="/ressources/360/godafoss.jpg"
      altitudeParDefaut={30}
      offsetNord={90}
    />
  )

  return (
    <LayoutGalerieImageInteractive
      page={page}
      propsPage={props}
      composantImageInteractive={composantImageInteractive}
      codePageRetour="galerieZoomsEt360Degres"
    >
      <section className="section-conteneur">
        <p>
          Si vous vous êtes déjà demandés ce que l’on ressent lorsque l’on est
          sous une aurore boréale très puissante, ce 360° réalisé sur un des
          plus beaux lieux de la planète à Godafoss en Islande devrait vous
          apporter une réponse.{" "}
        </p>
        <p>
          Regardez le zénith : l’aurore a pris une forme de visage humain, ne
          trouvez-vous pas ?
        </p>
        <div id="galerie-image-interactive-texte-exifs">
          Panoramique de 28 images, rotule panoramique Bushman Gobi, Canon EOS
          6D refiltré Astrodon, Samyang 24 mm F1.4 @ F2.2, 6400 ISO. Poses
          unitaires de 1 seconde.
        </div>
      </section>
    </LayoutGalerieImageInteractive>
  )
}
